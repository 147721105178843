<template>
  <div class="login-page">
    <v-container>
      <v-row justify="center" class="mt-5">
        <v-col cols="12" lg="5">

          <div class="text-center mb-5">
            <img alt="" src="../../assets/logo.svg" width="200"/><br/>
          </div>

          <v-card flat class="rounded-lg">

            <form @submit.prevent="login">
              <v-card-text class="pa-6 pa-lg-12">
                <h1 class="text-center mb-lg-8 mb-4">Connexion</h1>
                <v-alert
                    dense
                    v-if="error"
                    color="red"
                    dark
                    icon="mdi-information-outline"
                    border="left"
                    prominent
                >
                  {{ "Vérifiez vos identifiants" }}
                </v-alert>
                <v-text-field
                    outlined
                    prepend-inner-icon="mdi-email-outline"
                    type="text"
                    v-model="form.username"
                    :error-messages="error ? error.username : ''"
                    @input="error && error.username ? (error.username = '') : false"
                    label="Nom d'utilisateur *"
                >
                </v-text-field>
                <v-text-field
                    outlined
                    prepend-inner-icon="mdi-lock-outline"
                    v-model="form.password"
                    :error-messages="error ? error.password : ''"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    @input="error && error.password ? (error.password = '') : false"
                    label="Mot de passe *"
                >
                </v-text-field>
                <v-checkbox
                    label="Se souvenir de moi"
                    style="margin: -10px"
                ></v-checkbox>
                <v-btn
                    type="submit"
                    depressed
                    :loading="btnLoading"
                    large
                    block
                    color="primary"
                    class="rounded-lg mt-3"
                >
                  Connexion
                </v-btn>

                <div class="text-center mt-4 mb-4">
                  <router-link to="/forgot_password" class="text-decoration-none"
                  >Mot de passe oubliée ?
                  </router-link>
                </div>

                <v-divider/>

                <div class="text-center mt-4">
                  Pas un membre?

                    <router-link to="/gifty/register" class="text-decoration-none"
                    >S'inscrire maintenant
                    </router-link
                    >
                  </div>

              </v-card-text>
            </form>
          </v-card>
          <p class="text-center white--text mt-10">
            © Copyright {{ new Date().getFullYear() }}
            <strong class="text-primary">Gifty</strong> Tous droits réservés.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      btnLoading: false,
      error: null,
      form: {},
      show: false,
    };
  },
  methods: {
    login() {
      this.btnLoading = true;
      this.error = null;
      axios.post(process.env.VUE_APP_BASE_URL + "auth/login", this.form)
          .then((res) => {
            this.$store.dispatch("isLoggedIn", true)
            this.$store.dispatch("user", res.data)
            this.$store.dispatch('stores', [])
            this.btnLoading = false
            this.$router.go(0);
          })
          .catch((err) => {
            this.btnLoading = false;
            this.error = err.response.data.error;
            console.log(err);
          });
    },
  },
};
</script>

<style scoped></style>
